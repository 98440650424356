
// Variables
$light: #fff;
$white:#fff;

$body-color: #f6f8fa;
$primary-color: #5672f9;
$secondary-color: #fff;
$success: #59d659;
$black: #000;
$alert:#ff5252;
$border-color:#dedede;
$light-border:#F4F7F9;
$primary-font:'Muli', sans-serif;
$secondary-font:'Muli', sans-serif;
$text-color: #666;
$gray: #c7c7c7;
$heading-color: #333;
$dark-gray: #848484;
// $icon-font: 'themefisher-font';
