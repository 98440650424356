.navigation {
	padding:20px 0;
}


.login-button {
	border:2px solid $border-color;
	margin-right:8px;
	padding:6px 20px!important;
}

.add-button {
	background: #28a745;
	color:$white;
	padding:8px 20px!important;
	display: inline-block;
	&:hover {
		color:$white;
	}
}


// navbar
.main-nav{
	// navbar custom styles
		.nav-item{
			.nav-link{
				font-size: 16px;
				line-height: 43px;
				padding:0 20px;
				span{
					margin-left: 4px;
				}
				&.dropdown-toggle{
					padding-right: 10px;
				}
			}
			.dropdown-toggle::after{
			    border: none;
			} 
			&.active{
				.nav-link{
					color: $primary-color;				
				}
			}
		}
		// search button
		.search{
			.nav-link{
				i{
					font-size: 15px;
					height: 43px;
					width: 43px;
					line-height: 43px;
					color: $primary-color;
					border: 1px solid $primary-color;
					border-radius: 3px;
					text-align:center;
				}
			}
			.input-group{
				width: 300px;
				.input-group-addon{
					background: $primary-color;
					color: $light;
					border: 1px solid $primary-color;
					i{
						font-size: 15px;
						padding-right: 5px;
						padding-left: 5px;
					}
				}
			}
		}
	
}
// Dropdown Styles
.dropdown-menu{
	padding: 15px;
	border-radius:0;
}
.nav-item.dropdown{
	position: relative;
}
.dropdown-item.active, .dropdown-item:active{
	background: $primary-color;
}
.dropdown-item{
	font-size: 14px;
	padding: 10px 60px 10px 10px;
}

@media(min-width:768px){
 	.dropdown-slide {
	  position: static;
	  .open>a, .open>a:focus, .open>a:hover {
	    background: transparent;
	  }
	  &.full-width {
	    .dropdown-menu {
	      left:0!important;
	      right:0!important;
	    }
	  }
	  &:hover .dropdown-menu {
	    display:none;
	    opacity: 1;
	    display: block;
	    transform: translate(0px ,0px);
	    opacity: 1;
	    visibility: visible;
	    color: #777;
	    transform: translateY(0px);
	  }
	  .dropdown-menu {
	  	margin-top: 0;
	    border-radius:0;
	    opacity: 1;
	    visibility: visible;
	    position: absolute;
	    padding: 15px;
	    border: 1px solid #ebebeb;
	    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	    transition:.3s all;
	    position: absolute;
	    display: block;
	    visibility: hidden;
	    opacity: 0;
	    transform: translateY(30px);
	    transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
	    @include mobile {
	      transform:none;
	    }
	  }
	}
 }

// Container in mobile
@include mobile {
	.navbar-toggleable-md>.container{
		margin-right: 0;
		margin-left: 0;
		padding-left: 25px;
		padding-right: 25px;
	}
}