.product-item {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
	margin-bottom:30px;
	.card {
		border:none;
		.card-body {
			padding:20px;
			.card-title {
				font-size:18px;
				margin-bottom:5px;
				font-weight: 600;
			}
			.card-text {
				color:#777;
			}
		}

	}
	.thumb-content {
		position: relative;
		.price {
			position: absolute;
			top:10px;
			left:10px;
			background: $primary-color;
			color:$white;
			display: inline-block;
			padding:4px 8px;
			font-size:12px;
		}
	}
	.product-meta{
		margin-bottom:15px;
		li {
			margin-right:15px;
			a {
				color:#888;
				font-size:12px;
				i {
					margin-right:4px;
				}
			}
		}
	}
	.product-ratings {
		li {
			margin-right:1px;
			i {
				font-size:18px;
			    color: #dedede;
			    cursor: pointer;
			}
		}
		.selected {
			i {
				color:$primary-color;
			}
		}
	}
}


.product-details {
	.product-title	{
		font-size:25px;
		font-weight: 600;
	}
	.tab-title {
		border-bottom:1px solid $border-color;
		padding-bottom:10px;
		margin-bottom:20px;
	}
	.product-meta {
		li {
			color:#888;
			margin-right:25px;
			a {
				color:#333;
				margin-left:3px;
			}
		}
	}
	.content {
		padding-top:40px;
		h3 {
			font-size: 20px;
			font-weight: bold;
		}
		p {
			color:#888;
			line-height: 22px;
		}
		.nav {
			text-align: center;
			margin-bottom: 5px;
			.nav-item {
				margin-right:3px;
				.nav-link {
					padding:20px 30px;
					border-radius:0;
					background: $white;
					&:hover {
						background: $primary-color;
						color:$white;
					}
					&.active {
						background: $primary-color;
					}
				}
			}
		}
		.tab-content {
			background: $white;
			padding:30px;
		}
	}
}


.product-slider {
	padding:40px 0;
	.carousel-indicators li {
		background:#888;
		&.active {
			background: $primary-color;
		}

	}
}


.product-table {
	margin-top:20px;
	tr {
		td:first-child {
			background: #f9f9f9;
		}
		td {
			padding-left:25px;
		}
	}
}

.product-review {
	img {
		width: 60px;
		margin-right: 20px;
	}
	.name h5 {
	    font-size: 18px;
	    margin-bottom: 5px;
	}
	.media-body {
	    background: #fff;
	    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
	    padding: 30px;
	    margin-bottom: 30px;
	    border-radius: 2px;
	}
	.review-submission {
		h5 {
			font-size:16px;
			color:#888;
		}
		.rate {
			margin-bottom:20px;
			i {
				margin:0 5px;
			}	
		}
		.form-control {
			margin-bottom:8px;
			border-radius:0;
		}
		input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
			height:40px;
		}
	}
}
